import React, { useRef } from "react";

import SEO from "../components/seo";
import Layout from "../components/layout";
import ServicesHero from "../modules/hero/services-hero";
import FooterMapDetails from "../components/footer/footer-map-details";
import ModuleProject from "../modules/module-project";
import Aassociate from "../images/services/Case-Studies-Aassociate.jpeg";
import AVNT from "../images/services/Case-Studies-AVNT.jpeg";
import Blitz from "../images/services/Case-Studies-Blitz.jpeg";
import Sricles from "../images/services/Case-Studies-Sircles.jpeg";

const Services = ({ location }) => {
  const pageLayoutRef = useRef();

  const seo = {
    pageUrl: "/work",
    pageTitle: "Our Work | Vello | A full-service creative agency.",
    indexibility: "Indexable",
    metaDescription: "Our Work | Vello | A full-service creative agency.",
  };

  const footerDate = {
    location: location,
    menu: [
      {
        text: "Start a project",
        link: "/start-a-project",
      },
      {
        text: "Our Services",
        link: "/services",
      },
      {
        text: "Our Work",
        link: "/work",
      },
    ],
    description: `
    We define and develop solutions carefully, skillfully, and beautifully. Powered by a solid team of talented professionals, we collaborate globally to craft the best solutions for you.`,
  };

  const data = [
    {
      title: "associate.com",
      coverImage: Aassociate,
    },
    {
      title: "AVNT",
      coverImage: AVNT,
    },
  ];

  const featured = [
    {
      title: "associate.com",
      coverImage: Aassociate,
    },
    {
      title: "AVNT",
      coverImage: AVNT,
    },
    {
      title: "Sircles",
      coverImage: Sricles,
    },
    {
      title: "Blitz App",
      coverImage: Blitz,
    },
  ];

  return (
    <div ref={pageLayoutRef}>
      <SEO data={seo} location={location} />
      <Layout location={location}>
        <ServicesHero
          title={`Our Work.`}
          description={`We build impactful software solutions at the intersection of design, data, and technology.`}
          logoImage={""}
          pageLayoutRef={pageLayoutRef}
        />
        <ModuleProject
          projectIndex={1}
          title="Associate.com"
          slug="associate-com"
          pageLayoutRef={pageLayoutRef}
          coverImage={Aassociate}
          type="Customer Journey, Design"
        />
        <ModuleProject
          projectIndex={2}
          title="AVNT"
          slug="avnt"
          pageLayoutRef={pageLayoutRef}
          coverImage={AVNT}
          type="Design Ideation, Development"
        />
        <ModuleProject
          projectIndex={1}
          title="Sircles"
          slug="sircles"
          pageLayoutRef={pageLayoutRef}
          coverImage={Sricles}
          type="Development"
        />
        <ModuleProject
          projectIndex={2}
          title="Blitz App"
          slug="blitz"
          pageLayoutRef={pageLayoutRef}
          coverImage={Blitz}
          type="Development"
        />
        <FooterMapDetails {...footerDate} pageLayoutRef={pageLayoutRef} />
      </Layout>
    </div>
  );
};

export default Services;
