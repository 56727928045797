import React from "react";
import { graphql } from "gatsby";
import AnimationLink from "../components/animation-link";
import Img from "gatsby-image";
import styled from "styled-components";
import Section from "../components/section";
import media from "../styles/media";

// Module Project Fragment
export const moduleProjectFragment = graphql`
  fragment ModuleProjectFragment on Node {
    ... on ContentfulModuleProject {
      __typename
      contentful_id
      name
      title
      slug
      type
      coverImage {
        title
        fluid(
          maxWidth: 800
          quality: 95
          toFormat: JPG
          cropFocus: FACES
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid
        }
      }
      illustrations {
        title
        fluid(
          maxWidth: 800
          quality: 95
          toFormat: JPG
          cropFocus: FACES
          resizingBehavior: FILL
        ) {
          ...GatsbyContentfulFluid
        }
      }
    }
  }
`;

const ModuleProject = (props) => {
  const { title, slug, type, projectIndex, coverImage, pageLayoutRef } = props;
  const reverse = projectIndex % 2 === 0;

  return (
    <Section>
      <StyledContainer>
        <ContentWrapp reverse={reverse}>
          <AnimationLink
            navLink={`/projects/${slug}`}
            pageLayoutRef={pageLayoutRef}
          >
            <StyledTitle>{title}</StyledTitle>
          </AnimationLink>
          <StyledPorjectType>{type}</StyledPorjectType>
        </ContentWrapp>
        <StyledImgWrapp reverse={reverse}>
          <AnimationLink
            navLink={`/projects/${slug}`}
            pageLayoutRef={pageLayoutRef}
          >
            <StyledProjectImgWrapp reverse={reverse}>
              <img src={coverImage} alt={title} />
            </StyledProjectImgWrapp>
          </AnimationLink>
        </StyledImgWrapp>
      </StyledContainer>
    </Section>
  );
};

export default ModuleProject;

const StyledContainer = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
  margin-bottom: 76px;
  ${media.thone`display: block; margin-top: 46px; margin-bottom: 46px;`};
`;

const ContentWrapp = styled.div`
  position: relative;
  grid-row: 1 / -1;
  grid-column: 3 / span 5;
  z-index: 1;
  text-align: center;

  grid-column: ${({ reverse }) => (reverse ? 6 : 3)} / span 5;
  ${media.tablet`grid-column: ${({ reverse }) => (reverse ? 7 : 2)} / span 6;`};
  ${media.thone`text-align: left;`};
`;

const StyledImgWrapp = styled.div`
  grid-row: 1 / -1;
  position: relative;
  grid-column: ${({ reverse }) => (reverse ? 1 : 8)} / span 5;
  ${media.desktop`grid-column: ${({ reverse }) =>
    reverse ? 1 : 7} / span 6;`};

  a {
    width: 100%;
  }
`;

const StyledProjectImgWrapp = styled.div`
  flex: 1;
  height: 100%;
  .gatsby-image-wrapper {
    height: 100%;
    object-fit: cover;
    width: auto;
  }
  cursor: pointer;
  transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
  &:hover {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.41, 0, 0.019, 1);
    transform: ${({ reverse }) =>
      reverse ? `translateX(6px);` : `translateX(-6px);`};
  }
  img {
    width: 100%;
  }
`;

const StyledTitle = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 3rem;
  line-height: 108.1%;
  ${media.bigDesktop`font-size: 3rem;`};
  ${media.desktop`font-size: 2.5rem; line-height: 91.6%;`};
  ${media.tablet`font-size: 2rem;`};
`;

const StyledPorjectType = styled.div`
  padding-top: 10px;
  font-style: normal;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 91.6%;
  ${media.bigDesktop`font-size: 1.2rem;`};
  ${media.tablet`font-size: 1.1rem;`};
  ${media.thone`font-size: 1rem; margin-bottom: 10px;`};
`;
